import { IIcon } from "./Icon.types";

interface IClipboardOn extends IIcon {
  testId?: string;
  strokeColor?: string;
}

const ClipboardOn: React.FC<IClipboardOn> = ({
  width = "24",
  height = "24",
  viewBox = "4 4 24 24",
  onClick,
  className,
  testId,
  strokeColor = "#666972",
}: IClipboardOn): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      onClick={onClick}
      className={className}
      data-testid={testId}
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.58 25.702c.12.093.268.144.42.145.152 0 .3-.052.42-.145 3.823-2.97 6.456-5.528 8.299-8.052 2.351-3.224 2.888-6.202 1.595-8.85-.921-1.89-3.575-3.423-6.663-2.536A6.786 6.786 0 0016 8.856a6.786 6.786 0 00-3.651-2.592C9.255 5.363 6.607 6.91 5.686 8.8c-1.293 2.647-.757 5.624 1.595 8.85 1.843 2.523 4.476 5.08 8.3 8.051z"
      ></path>
    </svg>
  );
};

export default ClipboardOn;
