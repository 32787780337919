import { cart } from "@ecp-redux/api/cart";
import { searchResults } from "@ecp-redux/api/searchResults";

import { IElementButton } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  TButtonId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isB2BClient } from "@ecp-redux/helpers";
import { IBoxAddToCartProps } from "../../../../../boxes/BoxAddToCart/BoxAddToCart.types";
import { useCommunicationBetweenBoxesContext } from "../../../../../structure/Contexts/CommunicationBetweenBoxes";
import { useBoxContext } from "../../../../../structure/Contexts/BoxContext";
import StyledButton from "../../../../styleElements/StyledButton/StyledButton";
import { useOpenPortalSnackbar } from "../../../Snackbar/Snackbar";
import { findCartLineBySku } from "../../CartB2B/cartB2B.methods";
import { useCartB2BContext } from "../../CartB2B/useCartB2BContext";
import { useCart } from "../cart.methods";
import { StyledAddToCartWrapper } from "./AddToCart.styled";
import { TRegularBoxType } from "@ecp-pageTypes";
import { boxTypeToEventOrigin } from "../../../../../boxes/analytics/helpers";
import { eventAddToCart } from "../../../../../boxes/analytics/events";
import useSetSessionStorage from "../../../../../shared/hooks/useSetSessionStorage";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";

interface IAddToCartProps {
  buttonText: string;
  buttonStyle: IElementButton | TButtonId;
  itemId: string | undefined;
  boxType: TRegularBoxType;
}

const AddToCart: React.FC<IAddToCartProps> = ({
  buttonText,
  buttonStyle,
  itemId,
  boxType,
}) => {
  const [postAddToCart, result] = cart.usePostAddProductQuantityMutation();

  const { addToCartQuantity, chosenUnit } =
    useCommunicationBetweenBoxesContext();

  const { settings, messages } = useBoxContext<IBoxAddToCartProps>();
  const { currencyShort } = useLanguageCurrencyContext();
  const { getSessionStorageItem } = useSetSessionStorage();
  const customer_id =
    parseInt(getSessionStorageItem("customer_id") as string) || null;

  const { openSnackbarWithCartError } = useCart();
  const { cartData, addToCart } = useCartB2BContext();

  const { openPortalSnackbar } = useOpenPortalSnackbar();

  const [getB2BProduct] = searchResults.useLazyGetSearchWithSrpResultsV2Query();

  const isB2BClientType = isB2BClient();

  const handleAddToCart = async () => {
    if (itemId) {
      try {
        let unitOfMeasure = undefined;
        let quantity =
          ("add_to_cart_quantity" in settings &&
            settings.add_to_cart_quantity === "true") ||
          boxType === "SEARCH_RESULTS" ||
          boxType === "PRODUCT_SLIDER"
            ? addToCartQuantity.quantity
            : 1;
        if (isB2BClientType) {
          const product = await getB2BProduct({
            projection: [
              "mainUnitOfMeasurement",
              "sku",
              "netPrice",
              " basePrice",
              "grossPrice",
              "suggestedPrice",
              "alternativeUnitOfMeasures",
              "deliveryQuantity",
              "mainUnitOfMeasurementLabel",
            ],
            searchQueries: `filter=sku:${itemId}`,
          });
          const targetProduct = product?.data?.products?.find(
            ({ sku }) => sku === itemId
          );
          const { deliveryQuantity = 1 } = targetProduct ?? {};

          const targetAlternativeUnitOfMeasures =
            targetProduct?.alternativeUnitOfMeasures.find(
              (auom) => auom.auoM === chosenUnit
            )?.value || deliveryQuantity;

          quantity =
            targetAlternativeUnitOfMeasures +
              findCartLineBySku(cartData, {
                sku: itemId,
              }).quantity || deliveryQuantity;
          unitOfMeasure =
            chosenUnit || product?.data?.products?.[0]?.mainUnitOfMeasurement;

          await addToCart({
            sku: itemId,
            quantity,
            isCart: false,
            unitOfMeasure,
          })
            .unwrap()
            .then(async (data) => {
              if (!data.hasNotificationErrors) {
                openPortalSnackbar(
                  settings.add_to_cart_success_snackbar,
                  messages.add_to_cart_success_message
                );
                eventAddToCart({
                  product_id: itemId,
                  customer_id: customer_id,
                  quantity,
                  net_sales_price: product?.data?.products?.[0]?.netPrice,
                  sales_price: product?.data?.products?.[0]?.grossPrice,
                  base_price: product?.data?.products?.[0]?.basePrice,
                  suggested_retail_price:
                    product?.data?.products?.[0]?.suggestedPrice,
                  currency: currencyShort,
                  origin: boxTypeToEventOrigin(boxType),
                });
              } else {
                openPortalSnackbar(
                  settings.add_to_cart_error_snackbar,
                  messages.add_to_cart_error_message
                );
              }
            });
        } else {
          await postAddToCart({
            sku: itemId,
            quantity,
            isCart: false,
            unitOfMeasure,
          })
            .unwrap()
            .then(async (data) => {
              openPortalSnackbar(
                settings.add_to_cart_success_snackbar,
                messages.add_to_cart_success_message
              );
              if (data) {
                const chosenProduct = data.cartViewResponse?.orderLines.filter(
                  (line) => line.productSku === itemId
                )[0];

                eventAddToCart({
                  product_id: itemId,
                  customer_id: customer_id,
                  quantity,
                  net_sales_price: chosenProduct?.nettoPrice,
                  sales_price: chosenProduct?.salePrice,
                  base_price: chosenProduct?.price,
                  suggested_retail_price: chosenProduct?.suggestedRetailPrice,
                  currency: currencyShort,
                  origin: boxTypeToEventOrigin(boxType),
                });
              }
            });
        }
      } catch (e) {
        openSnackbarWithCartError(e);
      }
    }
  };

  return (
    <StyledAddToCartWrapper
      className="add-to-cart__container"
      $alignment={
        "add_to_cart_aligment" in settings
          ? settings.add_to_cart_aligment
          : AlignmentHorizontalOption.CENTER
      }
    >
      <StyledButton
        className="add-to-cart__button"
        onClick={handleAddToCart}
        renderAs="button"
        role="button"
        $settings={buttonStyle}
        disabled={result.isLoading}
      >
        {buttonText}
      </StyledButton>
    </StyledAddToCartWrapper>
  );
};

export default AddToCart;
